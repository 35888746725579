'use client'

import Link from 'next/link'
import { useRouter } from 'next/navigation'

import { Lang } from '@/i18n'
import { useTranslation } from 'react-i18next'

export default function NotFound(a: any) {
  const { i18n } = useTranslation()
  const router = useRouter()

  const handleClick = () => {
    if (window.history.length > 2) {
      router.back()
    } else {
      router.push(`/${i18n.language}/offers`)
    }
  }

  return (
    <main className='flex flex-auto grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-red-500'>404</p>
        <h1 className='mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl'>Page not found</h1>
        <p className='mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8'>Sorry, we couldn’t find the page you’re looking for.</p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <button
            className='rounded-md border py-2 px-4 w-40
                  bg-red-500 text-white hover:bg-white hover:text-red-500 hover:border-red-500
                  text-center text-sm transition-all shadow-sm hover:shadow-lg'
            type='button'
            onClick={handleClick}
          >
            Go back
          </button>
          <a href='mailto:support@esimfly.app'>
            <button
              className='flex gap-2 rounded-md py-2 px-4 w-40
                  bg-white text-red-500 border border-red-500
                  hover:bg-white hover:text-red-500 hover:border-red-500
                  text-center text-sm transition-all shadow-sm hover:shadow-lg'
              // className='flex gap-2 px-4 py-2 bg-white text-red-500 border border-red-500 rounded-full transition-all duration-500'
            >
              Contact support
              <span aria-hidden='true'>&rarr;</span>
            </button>
          </a>
        </div>
      </div>
    </main>
  )
}
